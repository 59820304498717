import React from 'react';
import { Button, ButtonGroup, TextLink, TextLinkCaret } from '@vds/buttons';
import { withVDSManager } from '@vds/utilities';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Title } from '@vds/typography';

export const Spacing = styled.div`
  margin: ${(props) => props.margin || ''};
  padding: ${(props) => props.padding || ''};
  ${(props) => props.$breakpoint ? `
      @media screen and (max-width: ${props.$breakpoint}) {
        margin: ${props.$breakPoinStyles?.margin || ''};
        padding: ${props.$breakPoinStyles?.padding || ''};
      }`
      : ''}
  @media screen and (max-width: 767px) {
    margin: ${(props) => props.mobileMargin || ''};
    padding: ${(props) => props.mobilePadding || ''};
    height: ${(props) => props.$height || ''};
    ${(props) => (props.$mobileTopAuto ? 'margin-top: auto;' : '')}
    ${(props) => (props.$mobileFlex ? 'display: flex; flex-direction: column;' : '')}
    ${(props) => (props.$bottomFixed ? `position: fixed;bottom: 16px; width: calc(100% - 32px);` : ``)}
  }
`;

export const VdsManagerButton = withVDSManager((props) => {
  const { desktopWidth, mobileWidth } = props.btnProps || {};
  const btnWidth = props.viewport === 'desktop' || props.viewport === 'tablet' ? desktopWidth : mobileWidth;
  return (
    <Button {...props.btnProps} width={btnWidth}>
      {props.children}
    </Button>
  );
});

export const VdsManagerButtonGroup = withVDSManager((props) => {
  const { desktopWidth, mobileWidth, data, viewport, ...rest } = props;
  let buttons = props?.data || [];
  const btnWidth = props.viewport === 'desktop' || props.viewport === 'tablet' ? desktopWidth : mobileWidth;
  buttons = buttons.map((item) => {
    const item1 = item;
    item1.width = btnWidth;
    return item1;
  });
  return <ButtonGroup viewport={viewport} data={buttons} {...rest} />;
});

export const HomeGrid = styled.div`
  display: flex;
  align-items: ${(props) => props.align || ''};
  ${(props) =>
    props.breakpoint
      ? `@media screen and (max-width: ${props.breakpoint}) {
    flex-wrap: wrap;
  }`
      : ''}
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
  }
`;

export const HomeCol = styled.div`
  width: 100%;
  ${(props) =>
    props.breakpoint
      ? `@media screen and (max-width: ${props.breakpoint}) {
    width: ${props.width || '100%'} ;
    padding: ${props.padding || ''}};
  }`
      : ''}
  @media screen and (max-width: 767px) {
    width: 100%;
    padding: 0px;
  }
`;
export const TextStyle = styled.div`
  white-space: ${(props) => (props.noWrap ? 'nowrap' : '')};
  @media screen and (max-width: 767px) {
    white-space: normal;
  }
`;

export const InputWrapper = styled.div`
  width: ${(props) => props.width};
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const SkeletonWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.bgColor || 'transparent'};
`;

export const manageBtnProps = (btns, desktopWidth = 'auto', mobileWidth = '100%') => {
  const btnData = btns;
  btnData.children = btns.Btntext;
  if (btns.primary) {
    btnData.use = 'primary';
  }
  if (btns.secondary) {
    btnData.use = 'secondary';
  }
  if (btns.datatracker) {
    btnData['data-track'] = btns.datatracker;
  }
  btnData.size = 'large';
  btnData.onClick = btns.clickBtn;
  btnData.desktopWidth = desktopWidth;
  btnData.mobileWidth = mobileWidth;
  return btnData;
};
export const HOMELQSuccessWrapper = (props) => {
  const { buttons = [], links = [], spacing, desktopWidth = 'auto', mobileWidth = '100%' } = props;
  const singleBtn = buttons?.length === 1 ? manageBtnProps(buttons[0], desktopWidth, mobileWidth) : null;
  const multiBtn = buttons?.length > 1 ? buttons.map((item) => manageBtnProps(item, desktopWidth, mobileWidth)) : null;
  return (
    <>
      {singleBtn && (
        <Spacing padding={`${spacing.xxxLarge} 0px 0px 0px`} mobilePadding={`${spacing.xxLarge} 0px 0px 0px`}>
          <VdsManagerButton btnProps={singleBtn}>{singleBtn.children}</VdsManagerButton>
        </Spacing>
      )}
      {multiBtn && <VdsManagerButtonGroup btnProps={multiBtn} />}
      {links &&
        links?.map((item) => {
          const DecideLinkComp = item.isCaret ? TextLinkCaret : TextLink;
          return (
            <Spacing padding={`${spacing.xLarge} 0px 0px 0px`}>
              <Title size="small">
                <DecideLinkComp {...item}>{item.linkText}</DecideLinkComp>
              </Title>
            </Spacing>
          );
        })}
    </>
  );
};

HOMELQSuccessWrapper.propTypes = {
  spacing: PropTypes.any,
  desktopWidth: PropTypes.any,
  mobileWidth: PropTypes.any,
  buttons: PropTypes.any,
  links: PropTypes.any,
};
