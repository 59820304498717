import { AddressQualification as ActionTypes } from '../../constants/actionTypes';
import { lqResponseObj } from '../../utils/lq-response-tranformer';

const initialState = {
  lqResult: lqResponseObj,
  scEmail: '',
  scMtn: '',
  details: {
    firstName: '',
    middleName: '',
    lastName: '',
    DateOfBirth: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    city: '',
    state: '',
    zipCode: '',
  },
  comboResponseObj: {
    emailId: '',
    mtn: '',
    languageIndicator: '',
    isComboEmailflow: '',
    acctRegistrationStatus: '',
    mtnActivationStatus: '',
  },
  isAcpPresent: false,
  isVfpPresent: window.sessionStorage?.getItem('isVfExist') || false,
  formattedMtn: '',
  helpView: false,
  lqsTagTriggered: false,
  maploadedJsNotifyTriggered: false,
  validateEmailRegex: false,
  validateEmailAddress: '',
  verifyEmail: false,
  manualValidationClick: false,
  error: false,
  success: false,
  errorTextForEmail: '',
  smartCartPdm: 'N',
  emeriosBody: {},
};

const addressQualificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.QUALIFICATION_DETAILS:
      return { ...state, lqResult: action.value };
    case ActionTypes.RESET_QUALIFICATION_DETAILS:
      return initialState;
    case ActionTypes.RESET_QUALIFICATION_DETAILS_LQRESULT:
      return { ...state, lqResult: lqResponseObj };
    case ActionTypes.SC_EMAIL:
      return { ...state, scEmail: action.value };
    case ActionTypes.SC_MTN:
      return { ...state, scMtn: action.value };
    case ActionTypes.SC_DETAILS:
      return { ...state, details: action.value };
    case ActionTypes.COMBO_RESPONSE:
      return { ...state, comboResponseObj: action.value };
    case ActionTypes.SC_ACP:
      return { ...state, isAcpPresent: action.value };
    case ActionTypes.SC_VFP:
      return { ...state, isVfpPresent: action.value };
    case ActionTypes.EMERIOS_BODY:
      return { ...state, emeriosBody: action.value };
    case ActionTypes.FORMATTED_MTN:
      return { ...state, formattedMtn: action.value };
    case ActionTypes.HELP_VIEW:
      return { ...state, helpView: action.value };
    case ActionTypes.RESTORE_DEFAULTS:
      return {
        ...state,
        scEmail: '',
        scMtn: '',
        formattedMtn: '',
      };
    case ActionTypes.LQSTAG_TRIGGERED:
      return { ...state, lqsTagTriggered: action.value };
    case ActionTypes.MAPTAG_TRIGGERED:
      return { ...state, maploadedJsNotifyTriggered: action.value };
    case ActionTypes.VALIDATE_EMAIL_REGEX:
      return { ...state, validateEmailRegex: action.value };
    case ActionTypes.VALIDATE_EMAIL_ADDRESS:
      return { ...state, validateEmailAddress: action.value };
    case ActionTypes.VERIFY_EMAIL:
      return { ...state, verifyEmail: action.value };
    case ActionTypes.MANUAL_VALIDATION_CLICK:
      return { ...state, manualValidationClick: action.value };
    case ActionTypes.ERROR:
      return { ...state, error: action.value };
    case ActionTypes.SUCCESS:
      return { ...state, success: action.value };
    case ActionTypes.ERROR_TEXT_FOR_EMAIL:
      return { ...state, errorTextForEmail: action.value };
    case ActionTypes.SMARTCART_PDM:
      return { ...state, smartCartPdm: action.value };
    default:
      return state;
  }
};

export default addressQualificationReducer;
