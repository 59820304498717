import { LoopQualification, AddressLookUp, Modal } from '../../constants/actionTypes';
import { fetchReCaptchaDetailsForNSA, fiosProfile } from '../../services/lqService';
import { captchaAttributesNSA } from '../../constants/lq-constants';
import { setEnvUrlObj } from '../../constants/api-urls';
import { checkTokenValidity, genarateToken, setToStorage } from '../../utils/lqServiceUtil';
import { catchJSException, logMetrics } from '../../services/logService';
import { LQ_VIEWS } from '../../constants/common';
import { resetQualificationDetials, greyOut } from '../address-qualification/actions';
import { updateFloor } from '../floor-lookup/action';
import { restoreDefaults } from '../address-lookup/actions';
import { restoreUnitDefaultsStore } from '../units-lookup/actions';
import defaultContent from '../../constants/static-content.json';
import { restoreFiosDefaults, updateVendorDetails } from '../order-fios/actions';
import { resetFivegDefault } from '../order-fiveg-home/actions';
import { RetrieveCustomerConstentTriggered } from '../service-unavailable/actions';
import { closeModalWindow, existingCustomerModal } from '../common/modal/action';
import { isFwaExisting, setPreferredBulkService } from '../bulk-lq/action';
import { resetSessionView } from '../common/SessionTimeout/action';

export const setFlowType = (flowType) => ({ type: LoopQualification.SET_FLOW_TYPE, value: flowType || '' });

export const updateParserAttempt = (parserAttempt) => ({ type: AddressLookUp.PARSER_ATTEMPT, value: parserAttempt });

export const updateParserAddressID = (parserAddressID) => ({ type: AddressLookUp.PARSER_ADDRESS_ID, value: parserAddressID });

export const updateParserShowAll = (parserShowAll) => ({ type: AddressLookUp.PARSER_SHOW_ALL, value: parserShowAll });

export const updateCurrentView = (targetView) => ({ type: LoopQualification.UPDATE_CURRENT_VIEW, value: targetView });

export const updatePlansPage = (plansPageUrl) => ({
  type: LoopQualification.REDIRECT_TO_PLANS_PAGE,
  value: { redirected: true, pageUrl: plansPageUrl },
});

export const ResetLandingDetails = () => ({ type: LoopQualification.REDIRECT_TO_PLANS_PAGE, value: { redirected: false, pageUrl: '' } });

export const updateWidgetHost = (widgetHost) => ({ type: LoopQualification.UPDATE_WIDGET_HOST, value: widgetHost });

export const updatePlatForm = (platForm) => ({ type: LoopQualification.SET_LQ_PLATFORM, value: platForm });
export const updateTagForm = (tagForm) => ({ type: LoopQualification.SET_LQ_TAGFORM, value: tagForm });
export const updateTWS = (TWS) => ({ type: LoopQualification.SET_TWS, value: TWS });
export const updateACP = (ACP) => ({ type: LoopQualification.SET_ACP, value: ACP });
export const updateVFEmailNc = (VFEmailNC) => ({ type: LoopQualification.SET_VF_EMAIL_NC, value: VFEmailNC });
export const updateVFResumeFlow = (VFResumeFlow) => ({ type: LoopQualification.SET_VF_RESUME_FLOW, value: VFResumeFlow });
export const updateEnrollCheck = (isEnrollTrue) => ({ type: LoopQualification.SET_VF_ENROLL_CHECK, value: isEnrollTrue });
export const updateMoversPh2 = (Mvph2) => ({ type: LoopQualification.SET_MVPH2, value: Mvph2 });
export const updateMoversPh2Titan = (Mvph2Titan) => ({ type: LoopQualification.SET_MVPH2_TITAN, value: Mvph2Titan });
export const updateDisableMoversPh2 = (disableMoversPh2) => ({ type: LoopQualification.SET_MVPH2_DISABLE, value: disableMoversPh2 });
export const updateHideMiniLq = (fccLqHide) => ({ type: LoopQualification.HIDE_FCC_MINILQ, value: fccLqHide });
export const updateFccFlow = (fccFlow) => ({ type: LoopQualification.IS_FCC_FLOW, value: fccFlow });
export const updateLq4 = (lq4) => ({ type: LoopQualification.SET_LQ4, value: lq4 });
export const updateHomeLQ = (homelq) => ({ type: LoopQualification.SET_HOMELQ, value: homelq });
export const updateAutoGeo = (autogeo) => ({ type: LoopQualification.SET_AUTOGEO, value: autogeo });
export const updateVfEc = (isVFEc) => ({ type: LoopQualification.SET_VFEC, value: isVFEc });
export const updateMDU = (isMdu) => ({ type: LoopQualification.SET_MDU, value: isMdu });
export const updateComboEmailFlow = (isComboEmailflow) => ({ type: LoopQualification.SET_COMBO_EMAIL_FLOW, value: isComboEmailflow });
export const updateNewConnection = (newConnection) => ({ type: LoopQualification.SET_NEW_CONNECTION, value: newConnection });
export const updateIsBulkQual = (isBulkQual) => ({ type: LoopQualification.SET_BULKQUAL, value: isBulkQual });
export const updateMhToken = (mhToken) => ({ type: LoopQualification.SET_MHTOKEN, value: mhToken });
export const updateSourceInfo = (sourceInfo) => ({ type: LoopQualification.SET_SOURCE_INFO, value: sourceInfo });
export const updateComboMFJT = (comboMFJT) => ({ type: LoopQualification.SET_COMBOMFJT, value: comboMFJT });
export const updateECComboMFJT = (ECcomboMFJT) => ({ type: LoopQualification.SET_EC_COMBOMFJT, value: ECcomboMFJT });
export const updateNycPh2 = (nycPh2) => ({ type: LoopQualification.SET_NYCPH2, value: nycPh2 });
export const updateRespLQ = (isResponsiveLQ) => ({ type: LoopQualification.SET_RESP_LQ, value: isResponsiveLQ });
export const updateSkeleton = (skeleton) => ({ type: LoopQualification.UPDATE_SKELETON_VIEW, value: skeleton });
export const updateFromPlansLQ = (isFromPLansLq) => ({ type: LoopQualification.UPDATE_FROM_PLANS_LQ, value: isFromPLansLq });

export const reloadReCaptchaDetails = (dispatch) => {
  try {
    if (window.location.search && window.location.search.toLowerCase().indexOf('captcha=n') !== 7) {
      fetchReCaptchaDetailsForNSA()
        .then((response) => {
          logMetrics('reloadReCaptchaDetails', JSON.stringify(response), 'Info');
          const recaptchaDetails = {};
          const captchaVal = response.showCaptcha === 'true' ? 'Y' : 'N';
          recaptchaDetails.reCaptchaEnabled = captchaVal;
          recaptchaDetails.reCaptchaVerified = response.blockUser === 'true';
          recaptchaDetails.reCaptchaJS = captchaAttributesNSA.reCaptchaJS;
          recaptchaDetails.reCaptchaSiteKey = captchaAttributesNSA.reCaptchaSiteKey;
          dispatch({ type: LoopQualification.UPDATE_RECAPTCHA_DETAILS, value: recaptchaDetails });
        })
        .catch((error) => {
          logMetrics('reloadReCaptchaDetails', error.message, 'Error');
        });
    }
  } catch (error) {
    catchJSException('LOOPQUAL2.0', 'reloadReCaptchaDetails', error);
    logMetrics('reloadReCaptchaDetails', error.message, 'Error');
  }
};
export const updateRecaptchaResponse = (value) => ({ type: LoopQualification.UPDATE_RECAPTCHA_RESPONSE, value });
export const resetRecaptchaResponse = () => updateRecaptchaResponse('');
export const updateValidCouponFlow = (value) => ({ type: LoopQualification.UPDATE_VALID_COUPONFLOW, value });
export const updateCustomerType = (value) => ({ type: LoopQualification.UPDATE_CUSTOMER_TYPE, value });
export const enableReCaptcha = () => ({ type: LoopQualification.UPDATE_RECAPTCHA_PROPERTY, value: { reCaptchaEnabled: 'Y' } });
export const getLoopQualAppStaticContent = (dispatch) => {
  dispatch({ type: LoopQualification.SET_STATIC_CONTENT, value: defaultContent });
};
export const setCheckAvailBtnTrack = (value, dispatch) => {
  dispatch({ type: LoopQualification.SET_CHECKAVAILBTNTRACK, value });
};
export const setNSAFlowParams = (isLoggedInStatus) => {
  const flowConfig = { isLoggedIn: isLoggedInStatus };
  setEnvUrlObj(flowConfig);
};
export const clearDetails = (dispatch, cookies, fiosDetails, loopQualApp, autoGeoQual) => {
  dispatch(updateAutoGeo(false));
  setToStorage('clearDetailsLQ', 'Y');
  if (loopQualApp?.homeLQ) {
    window.sessionStorage.removeItem('LqQualified');
  }

  const plansvisit = window?.vzwlqwidgetconfig?.sourceInfo === 'plansvisit';
  setCheckAvailBtnTrack('check avail - nav', dispatch);
  if (!loopQualApp.isFccFlow) {
    console.log('====DISPATCHING CLEARDETAIL====');
    document.dispatchEvent(new CustomEvent('lqw-qualification-success', { detail: [], bubbles: true }));
  }
  existingCustomerModal(dispatch, false);
  const vendorDetails = fiosDetails && fiosDetails.vendorDetails;
  dispatch(updateIsBulkQual(false));
  isFwaExisting(dispatch, false);
  dispatch(ResetLandingDetails());
  dispatch(updateCurrentView(LQ_VIEWS.ADDRESS_QUALIFICATION));
  dispatch(restoreDefaults());
  dispatch(RetrieveCustomerConstentTriggered(false));
  resetQualificationDetials(dispatch);
  // this.props.dispatch(Action.enableReCaptcha());
  restoreUnitDefaultsStore(dispatch);
  restoreFiosDefaults(dispatch);
  resetFivegDefault(dispatch);
  updateFloor('', dispatch);
  dispatch(resetSessionView());
  setPreferredBulkService(dispatch, null, null);
  if (cookies) {
    cookies.remove('visit_id', { path: '/', domain: 'verizon.com' });
  }
  window.sessionStorage.removeItem('isPreorder');
  window.localStorage.removeItem('isPreorder');
  window.sessionStorage.removeItem('SNGLPARSER');
  if (!plansvisit) {
    window.sessionStorage.removeItem('LQResponse');
    window.localStorage.removeItem('visit_id');
    window.localStorage.removeItem('visitor_id');
    window.sessionStorage.removeItem('visit_id');
    window.sessionStorage.removeItem('visitor_id');
  }
  window.sessionStorage.removeItem('lqaCalled');
  window.sessionStorage.removeItem('lqsCalled');
  window.sessionStorage.removeItem('fwa_lqaCalled');
  window.sessionStorage.removeItem('VFSelectedService');
  window.sessionStorage.removeItem('BaseNtas');
  // pending order response
  window.sessionStorage.removeItem('cartResponse');
  window.sessionStorage.removeItem('ExistingCase');
  window.sessionStorage.removeItem('FWA_LQSPO');
  if (vendorDetails) {
    updateVendorDetails(vendorDetails, dispatch);
  }
  if (cookies) {
    document.cookie = 'isMovers=false;path=/';
  }
  window.sessionStorage.removeItem('isMovers');
  if (loopQualApp?.homeLQ) {
    dispatch({ type: Modal.CLOSE_MODAL, value: '' });
  }
  if (loopQualApp && loopQualApp.lq4 && !autoGeoQual) {
    closeModalWindow(dispatch);
    setTimeout(() => {
      const streetInput = document.querySelector('#streetaddress');
      if (streetInput) {
        streetInput.focus();
        greyOut();
      }
    }, 1000);
  }
};

export const fetchFiosProfile = (dispatch, loopQualApp, notAcp, isLoggedInTWSTK) => {
  try {
    if (window.location.host.includes('localhost')) {
      fiosProfile(dispatch, loopQualApp, notAcp, false, isLoggedInTWSTK);
    } else if (!checkTokenValidity()) {
      genarateToken()
        .then((resp) => {
          if (resp.status === 200) {
            return new Promise((resolve) => {
              fiosProfile(dispatch, loopQualApp, notAcp, false, isLoggedInTWSTK);
              resolve();
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      return new Promise((resolve) => {
        fiosProfile(dispatch, loopQualApp, notAcp);
        resolve();
      });
    }
  } catch (error) {
    console.log(error);
  }
};
